import React from 'react';
import { useConfiguredRollbar } from '@vp/digital-rollbar-lib';

import './GoogleWorkspacePdpBPage.scss';
import { H1 } from '@vp/swan';

const googleWorkspacePdp = ({ pageContext }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useConfiguredRollbar();

  return <H1>{pageContext.pageData.hero.title}</H1>;
};

export default googleWorkspacePdp;
